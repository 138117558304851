const axios = require('axios');

const REFS = {
    User: '/users',
    Product: '/products',
    Recipe: '/recipes',
    Programm: '/programms',
};

class ApiClient {
    constructor(basePath, staticDownloadPath, paymentIntegration) {
        this.basePath = basePath;
        this.refs = REFS;
        this.paymentIntegration = paymentIntegration;
        this.requestClient = axios.create({
            baseURL: this.basePath
        });
        this.staticBucketDownloadPath = staticDownloadPath;
    }

    createImageHrefs(images) {
        return Array.isArray(images) ? images.map(image => `${this.staticBucketDownloadPath}/${image}`) : [];
    }

    async request({
        path,
        method = 'get',
        body,
        query,
        token
    } = {}) {
        const headers = {};
        if (token) {
            headers.Authorization = `Bearer ${token}`;
        }
        const response = await this.requestClient({
            url: path,
            method,
            params: query,
            data: body,
            headers
        });
        return response.data;
    }

    async login(params) {
        const body = await this.request({
            path: '/login',
            method: 'post',
            body: params,
        });
        return body.result;
    }

    async restore(params) {
        const body = await this.request({
            path: '/reset_password',
            method: 'post',
            body: params,
        });
        return body.result;
    }

    async changePasswordWithCode(code, params) {
        const body = await this.request({
            path: `/change_password/${code}`,
            method: 'put',
            body: params,
        });
        return body.result;
    }

    async verifyToken(token) {
        try {
            const body = await this.request({
                path: `/verify`,
                method: 'get',
                token,
            })
            return body.result;
        } catch (error) {
            console.error('Token verification failed', error);
            return;
        }
    }

    async getSelf(token) {
        const body = await this.request({
            path: '/users/~/',
            method: 'get',
            token: token,
        });
        if (body.result && body.result.name) {
            body.result.firstLetter = body.result.name[0].toUpperCase();
        }
        return body.result;
    }

    async getUserParams(type, age, token) {
        const body = await this.request({
            path: `/user-params/type/${type}/age/${age}`,
            method: 'get',
            token: token,
        });
        return body.result;
    }

    async getProgrammsByIds(ids, token) {
        const body = await this.request({
            path: `/programms/${ids.join(',')}`,
            method: 'get',
            token: token,
        });
        return Array.isArray(body.result) ? body.result : [body.result];
    }

    async getTariffsByIds(ids, token) {
        const body = await this.request({
            path: `/tariffs/${ids.join(',')}`,
            method: 'get',
            token: token,
        });
        return Array.isArray(body.result) ? body.result : [body.result];
    }

    async confirmEmail(code) {
        const body = await this.request({
            path: `/users/confirm-email/${code}`,
            method: 'put'
        });
        return Array.isArray(body.result) ? body.result : [body.result];
    }

    async getPayments(userId, token) {
        const body = await this.request({
            path: `/users/${userId}/payments`,
            method: 'get',
            token: token,
        });
        return Array.isArray(body.result) ? body.result : [body.result];
    }

    async getKitchenware(token) {
        const body = await this.request({
            path: '/kitchenware',
            method: 'get',
            token: token,
        });
        return Array.isArray(body.result) ? body.result : [body.result];
    }

    async getExercisesByIds(ids, token) {
        const body = await this.request({
            path: `/exercises/${ids.join(',')}`,
            method: 'get',
            token: token,
        });
        return (Array.isArray(body.result) ? body.result : [body.result]).map(exercise => {
            exercise.imageHrefs = this.createImageHrefs(exercise.images);
            return exercise;
        });
    }

    async getTrainingsByIds(ids, token) {
        const body = await this.request({
            path: `/trainings/${ids.join(',')}`,
            method: 'get',
            token: token,
        });
        return Array.isArray(body.result) ? body.result : [body.result];
    }

    async getPracticesByIds(ids, token) {
        const body = await this.request({
            path: `/practices/${ids.join(',')}`,
            method: 'get',
            token: token,
        });
        return (Array.isArray(body.result) ? body.result : [body.result]).map(practice => {
            practice.imageHrefs = this.createImageHrefs(practice.images);
            return practice;
        });
    }

    async getRecipesByIds(ids, token) {
        const body = await this.request({
            path: `/recipes/${ids.join(',')}`,
            method: 'get',
            token: token,
        });
        return (Array.isArray(body.result) ? body.result : [body.result]).map(recipe => {
            recipe.imageHrefs = this.createImageHrefs(recipe.images);
            return recipe;
        });
    }

    async getProductsByIds(ids, token) {
        const body = await this.request({
            path: `/products/${ids.join(',')}`,
            method: 'get',
            token: token,
        });
        return (Array.isArray(body.result) ? body.result : [body.result]).map(product => {
            product.imageHrefs = this.createImageHrefs(product.images);
            return product;
        });
    }

    async getProducts(token, sortByName = false) {
        const body = await this.request({
            path: `/products`,
            method: 'get',
            query: sortByName ? { sort: 'title' } : {},
            token: token,
        });
        return (Array.isArray(body.result) ? body.result : [body.result]).map(product => {
            product.imageHrefs = this.createImageHrefs(product.images);
            return product;
        });
    }

    async createUser(object, token) {
        const body = await this.request({
            path: REFS.User,
            method: 'post',
            body: object,
            token,
        });
        return body.result;
    }

    async updateUser(object, token) {
        const body = await this.request({
            path: `${REFS.User}/~`,
            method: 'patch',
            body: object,
            token,
        });
        return body.result;
    }

    async getSimilarRecipes(recipeId, deviation = 0.1, token) {
        const body = await this.request({
            path: `${REFS.Recipe}/${recipeId}/similar`,
            method: 'get',
            query: {
                deviation
            },
            token,
        });
        return body.result;
    }

    async createPayment(object, token) {
        const body = await this.request({
            path: '/users/~/payments',
            method: 'post',
            body: {
                ...object,
                paymentIntegration: this.paymentIntegration
            },
            token,
        });
        return body.result;
    }

    async changeTariff(userId, tariffId, token) {
        const body = await this.request({
            path: `/users/${userId}/subscription/tariff`,
            method: 'put',
            body: {
                tariffId
            },
            token,
        });
        return body.result;
    }
}

module.exports = ApiClient;

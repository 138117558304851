import {Chart, registerables} from 'chart.js';

Chart.register(...registerables);

function getChartOptions(data) {
    return {
        type: 'bar',
        data: {
            labels: data.labels,
            datasets: [{
                label: 'Вес',
                data: data.values,
                barPercentage: 0.5,
                backgroundColor: [
                    'rgba(69, 87, 237, 1)',
                ],
                borderColor: [
                    'rgba(69, 87, 237, 1)'
                ],
                borderWidth: 1,
                borderRadius: 5,
                borderSkipped: false,
            }]
        },
        options: {
            plugins: {
                tooltip: {
                    enabled: true,
                    displayColors: false,
                    titleFont: { size: 16 },
                    bodyFont: { size: 14 },
                    cornerRadius: 20,
                    caretSize: 5,
                    padding: 20,
                    backgroundColor: '#222222',
                    titleFontStyle: 'normal',
                    titleMarginBottom: 10,
                    callbacks: {
                        label: function (context) {
                            var label = context.dataset.label || '';

                            if (label) {
                                label += ' – ';
                            }
                            if (context.parsed.y !== null) {
                                label += new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(context.parsed.y);
                            }
                            return label += ' кг';
                        },
                        labelTextColor: function (context) {
                            return 'white';
                        }
                    }
                },
                legend: {
                    display: false
                },
                title: {
                    display: true,
                    text: 'Динамика веса, кг'
                },
            },
            scales: {
                x: {
                    ticks: {
                        display: true
                    }
                },
                y: {
                    ticks: {
                        display: true
                    }
                }
            }
        }
    };
}

export function initHistoryChart($) {
    $('.history-chart').each(function() {
        const data = JSON.parse($(this).attr('data-chart-values'));
        const myChart = new Chart($(this), getChartOptions(data));
        myChart.update();
    });
}

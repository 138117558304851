import $ from 'jquery';

class UserFormValuesExtractor {
    getMeals(mealsCount) {
        if (mealsCount === 3) {
            return [true, false, true, false, true, false];
        } else if (mealsCount === 4) {
            return [true, true, true, false, true, false];
        } else if (mealsCount === 5) {
            return [true, true, true, true, true, false];
        } else if (mealsCount === 6) {
            return [true, true, true, true, true, true];
        }
    }

    isFieldExists(selector) {
        return $(selector).length;
    }

    getInt(selector) {
        return parseInt(this.getValue(selector));
    }

    getFloat(selector) {
        return parseFloat(this.getValue(selector));
    }

    getString(selector) {
        return this.getValue(selector).trim();
    }

    getValue(selector) {
        return $(selector).val();
    }

    // modifies argument
    addInt(params, field, selector) {
        if (this.isFieldExists(selector)) {
            params[field] = this.getInt(selector);
        }
    }

    // modifies argument
    addFloat(params, field, selector) {
        if (this.isFieldExists(selector)) {
            params[field] = this.getFloat(selector);
        }
    }

    // modifies argument
    addString(params, field, selector) {
        if (this.isFieldExists(selector)) {
            const value = this.getString(selector);
            if (value) {
                params[field] = value;
            }
        }
    }

    // modifies argument
    addValue(params, field, selector) {
        if (this.isFieldExists(selector)) {
            params[field] = this.getValue(selector);
        }
    }

    extractUserRegistrationParams() {
        const params = {
            ...this.extractUserParams(),
        };
        this.addString(params, 'inviterCode', '#inviterCode');
        this.addString(params, 'email', '#email');
        this.addInt(params, 'height', '#height');
        this.addInt(params, 'birthYear', '#birthYear');
        this.addInt(params, 'sex', 'input[name="sex"]:checked');
        return params;
    }

    extractUserParams() {
        const params = {};
        if (this.isFieldExists('#meals-count')) {
            params.meals = this.getMeals(this.getInt('#meals-count'));
        }
        this.addString(params, 'name', '#name');
        this.addString(params, 'surname', '#surname');
        this.addString(params, 'phone', '#phone');
        this.addInt(params, 'programmsUpdatePeriod', '#programms-update-period');
        this.addInt(params, 'height', '#height');
        this.addInt(params, 'weight', '#weight');
        this.addFloat(params, 'activityCoefficient', '#activityCoefficient');
        this.addInt(params, 'maxRecipeDifficulty', '#maxRecipeDifficulty');
        this.addInt(params, 'maxRecipePrice', '#maxRecipePrice');
        if (this.isFieldExists('#goals')) {
            params.goals = Array.isArray(this.getValue('#goals')) ? this.getValue('#goals') : [this.getValue('#goals')];
        }
        this.addInt(params, 'level', '#level');
        this.addValue(params, 'equipment', '#equipment');
        this.addValue(params, 'contraindications', '#contraindications');
        return params;
    } 
}

export {
    UserFormValuesExtractor
};

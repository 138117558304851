import {initLogin} from './forms/login';
import {initRestore} from './forms/restore';
import {initRestoreAction} from './forms/restoreAction';
import {initDisallowProducts} from './forms/disallowProducts';
import {initRegister} from './forms/userForm/register';
import {initPersonal} from './forms/userForm/personal';

import {initCompoundChart} from './charts/compound.js'
import {initHistoryChart} from './charts/history.js'
import { initChangeEmail } from './forms/changeEmail';
import { initCreatePayment } from './forms/createPayment';
import { initReplaceRecipe } from './forms/replaceRecipe';

export class Client {
    constructor($, apiClient) {
        this.$ = $;
        this.apiClient = apiClient;
    }

    initialize() {
        initCompoundChart(this.$);
        initHistoryChart(this.$);
        initLogin(this.$, this.apiClient);
        initRestore(this.$, this.apiClient);
        initChangeEmail(this.$, this.apiClient);
        initCreatePayment(this.$, this.apiClient);
        initRestoreAction(this.$, this.apiClient);
        initDisallowProducts(this.$, this.apiClient);
        initRegister(this.$, this.apiClient);
        initPersonal(this.$, this.apiClient);
        initReplaceRecipe(this.$, this.apiClient);
    }
}

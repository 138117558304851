import CookiesJS from 'cookies-js';
import {submitHandler} from './submit-handler';

export function initDisallowProducts($, apiClient) {
    const checkedProductsCounterEl = $('.checked-products-counter');
    let checkedCount = $('.exclude-product__input:checked').length;
    $('.exclude-product__input').on('change', function() {
        if ($(this).is(':checked')) {
            checkedCount++;
        } else {
            checkedCount--;    
        }
        checkedProductsCounterEl.html(checkedCount);
    });

    submitHandler('.action__disallow-products', async function () {
        const disallowedProducts = [];
        $('.exclude-product__input').each(function() {
            if ($(this).is(':checked')) {
                disallowedProducts.push($(this).val());
            }
        });
        const updates = {
            disallowedProducts,
            productsExcluded: true
        };
        await apiClient.updateUser(updates, CookiesJS.get('auth_token'));
        document.location.href='/';
    });
}

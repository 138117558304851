import {SubscriptionsService} from '../../lib/services/subscriptions';
import {submitHandler} from './submit-handler';
import CookiesJS from 'cookies-js';

export function initCreatePayment($, apiClient) {
    const subscriptionsService = new SubscriptionsService({
        apiClient,
    });

    submitHandler('.action__create-payment', async function () {
        const authToken = CookiesJS.get('auth_token');
        const newTariffId = $(this).attr('data-tariffId');
        const {subscription, user} = await apiClient.verifyToken(authToken);

        let tariff;
        if (newTariffId && subscription.tariffId !== newTariffId) {
            const [newTariff] = await apiClient.getTariffsByIds([newTariffId], authToken);
            if (!newTariff) {
                throw new Error('Тариф не найден');
            }
            tariff = newTariff;
            await apiClient.changeTariff(user._id, newTariffId, authToken)
        } else {
            const [oldTariff] = await apiClient.getTariffsByIds([subscription.tariffId], authToken);
            tariff = oldTariff;
        }

        const {
            nextProlongateCost
        } = subscriptionsService.calculatePersonalCosts(subscription, tariff);

        const payment = await apiClient.createPayment({
            amount: nextProlongateCost
        }, authToken);

        document.location.href = payment.yookassaPayment.confirmation.confirmation_url;
    });
}

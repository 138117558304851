import CookiesJS from 'cookies-js';
import {submitHandler} from './submit-handler';

export function initRestoreAction($, apiClient) {
    submitHandler('form.restore-action-form .action__restore-action', async function () {
        const params = {
            new_password: $('#new_password').val(),
            new_password_confirm: $('#new_password_confirm').val(),
        };
        const url = new URL(document.location.href);
        const code = url.pathname.split('/')[2];
        const result = await apiClient.changePasswordWithCode(code, params);
        if (!result.token) {
            throw new Error('Auth failed.');
        }
        if (!result.user) {
            throw new Error('Auth failed.');
        }
        CookiesJS.set('auth_token', result.token, { expires: 2147483647 });
        const user = await apiClient.getSelf(result.token);
        if (user.productsExcluded) {
            document.location.href='/';
        } else {
            document.location.href='/exclude';
        }
    });
}
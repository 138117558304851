const PAYMENT_NOTIFICATION_TIME = 1000 * 60 * 60 * 24 * 4;

class SubscriptionsService {
    constructor({
        apiClient
    } = {}) {
        this.apiClient = apiClient;
    }

    calculatePersonalCosts(subscription, tariff) {
        // price with personal discount
        const personalCost = this.getCostForSubscription(subscription, tariff);

        // how much should user pay to prolongate to one period
        const prolongateCost = this.sanitizePriceToPay(personalCost - subscription.balance);

        // this should be used for the next payment
        const nextProlongateCost = prolongateCost || personalCost;
        return {
            personalCost,
            prolongateCost,
            nextProlongateCost
        };
    }

    async getSubscription({
        authToken
    } = {}) {
        const {subscription} = await this.apiClient.verifyToken(authToken);
        const subsctiptionIsActive = subscription && subscription.isActive;
        const [tariff] = await this.apiClient.getTariffsByIds([subscription.tariffId], authToken);
        const {
            personalCost,
            prolongateCost,
            nextProlongateCost
        } = this.calculatePersonalCosts(subscription, tariff);

        const prolongateTimes = personalCost ? Math.floor(subscription.balance / personalCost) : 10000;
        const prolongateTime = tariff.period * prolongateTimes;
        const activeToWithProlongation = new Date(new Date(subscription.activeTo).getTime() + prolongateTime);
        const showPaymentNotification = subsctiptionIsActive && activeToWithProlongation - Date.now() < PAYMENT_NOTIFICATION_TIME;

        return {
            showPaymentNotification,
            subsctiptionIsActive,
            tariff,
            subscription,
            activeToWithProlongation,
            hasDiscount: !!subscription.discountCost || !!subscription.discountPercent,
            personalCost,
            prolongateCost,
            nextProlongateCost,
        };
    }

    getCostForSubscription(subscription, tariff) {
        if (subscription.discountCost) {
            return this.sanitizePriceToPay(tariff.cost - subscription.discountCost);
        }
        if (subscription.discountPercent) {
            return this.sanitizePriceToPay(tariff.cost * ((100 - subscription.discountPercent) / 100));
        }
        return this.sanitizePriceToPay(tariff.cost);
    }

    sanitizePriceToPay(value) {
        const positive = value < 0 ? 0 : value;
        return Math.ceil(positive * 100) / 100
    }

    async getPayments({
        authToken,
        userId
    } = {}) {
        const payments = await this.apiClient.getPayments(userId, authToken);
        return payments;
    }
}

module.exports = {
    SubscriptionsService
};

import $ from 'jquery';

const CALL_DELAY = 1000;

function submitHandler(submitSelector, handler) {
    let calledAt;
    $('.common-form-error').hide();
    $('.common-form-success-message').hide();
    $(submitSelector).on('click', async function () {
        if (calledAt && new Date() - calledAt < CALL_DELAY) {
            return;
        }
        calledAt = new Date();
        $('.common-form-error').hide();
        $('.common-form-success-message').hide();
        $('.floating-input').removeClass('error');
        try {
            await handler();
            $('.common-form-success-message').show();
        } catch (e) {
            if (e && e.handled) return;

            if (e &&
                e.response &&
                e.response.data &&
                e.response.data.errors &&
                e.response.data.errors.length &&
                e.response.status === 400
            ) {
                if (e.response.data.errors[0].formattedMessage) {
                    $('.common-form-error').html(e.response.data.errors[0].formattedMessage);
                    $('.common-form-error').show();
                }
                for (const error of e.response.data.errors) {
                    if (error.param && !$(`#${error.param}`).hasClass('error')) {
                        $(`#${error.param}`).addClass('error');
                    }
                }
                return;
            }

            $('.common-form-error').html('Что-то пошло не так. Попробуйте позже.');
            $('.common-form-error').show();

            console.error(e);
            if (e.response) {
                console.log(e.response);
            }
        }
    });
};

export {
    submitHandler
};

import {submitHandler} from './submit-handler';

export function initRestore($, apiClient) {
    submitHandler('form.restore-form .action__restore', async function () {
        const params = {
            email: $('#email').val(),
        };
        await apiClient.restore(params);
        document.location.href='/complete';
    });
}

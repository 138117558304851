import {submitHandler} from '../submit-handler';
import {UserFormValuesExtractor} from './userFormValuesExtractor';

export function initRegister($, apiClient) {
    const valuesExtractor = new UserFormValuesExtractor();

    let termsAccepted = $('form.register-form #accept-terms-checkbox').is(":checked");

    $('form.register-form #accept-terms-checkbox').on('click', function () {
        termsAccepted = $('form.register-form #accept-terms-checkbox').is(":checked");
        $('form.register-form .action__register').prop('disabled', !termsAccepted);
    });

    submitHandler('form.register-form .action__register', async function () {
        if (!termsAccepted) return;
        const user = valuesExtractor.extractUserRegistrationParams();
        await apiClient.createUser(user);
        await apiClient.restore({ email: user.email });
        document.location.href='/complete';
    });
}

import {UserFormValuesExtractor} from './userFormValuesExtractor';
import CookiesJS from 'cookies-js';
import {submitHandler} from '../submit-handler';

export function initPersonal($, apiClient) {
    const valuesExtractor = new UserFormValuesExtractor();
    submitHandler('form.personal-form .action__personal', async function () {
        const user = valuesExtractor.extractUserParams();
        await apiClient.updateUser(user, CookiesJS.get('auth_token'));
    });
}

const {Config} = require('../lib/config');
const axios = require('axios');

async function readConfigFromAPI() {
    const { data } = await axios({
        url: '/config',
        method: 'get'
    });
    return new Config(data);
}

module.exports = {
    readConfigFromAPI
};

import CookiesJS from 'cookies-js';
import {submitHandler} from './submit-handler';

export function initLogin($, apiClient) {
    let isEmailValid = false;
    let isPasswordValid = false;

    function validEmail (email) {
        return email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    }

    function validAll () {
        return isEmailValid && isPasswordValid;
    }

    function updateButtonState() {
        if (validAll()) {
            $('form.login-form .action__login').prop('disabled', false);
        }
        else {
            $('form.login-form .action__login').prop('disabled', true);
        }
    }

    $('#password').on("keyup", function() {
        if ($(this).val().length > 0 ) {
            isPasswordValid = true;
        }
        else {
            isPasswordValid = false;
        }
        updateButtonState(); 
    });
    
    $('#email').on("keyup", function() {
        if (validEmail($(this).val())) {
            $('#error-message__email').hide();
            isEmailValid = true;
        }
        else {
            $('#error-message__email').show();
            isEmailValid = false;
        }
        updateButtonState(); 
    });

    submitHandler('form.login-form .action__login', async function () {
        const params = {
            password: $('#password').val(),
        };
        if ($('#email').val()) params.email = $('#email').val();
        let result;
        try {
            result = await apiClient.login(params);
            CookiesJS.set('auth_token', result.token, { expires: 2147483647 });
            const user = await apiClient.getSelf(result.token);
            if (user.productsExcluded) {
                document.location.href='/';
            } else {
                document.location.href='/exclude';
            }
        } catch (error) {
            if (error && error.response && error.response.status === 403) {
                $('.common-form-error').html('Неверный логин или пароль');
                $('.common-form-error').show();
                error.handled = true;
            }
            throw error;
        }
    });
}

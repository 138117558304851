import {submitHandler} from './submit-handler';
import CookiesJS from 'cookies-js';

export function initChangeEmail($, apiClient) {
    submitHandler('form.change-email-form .action__change-email', async function () {
        const params = {
            email: $('#email').val(),
        };
        await apiClient.updateUser(params, CookiesJS.get('auth_token'));

        document.location.href='/change-email/complete';
    });
}

import CookiesJS from 'cookies-js';

const CALL_DELAY = 1000;

export function initReplaceRecipe($, apiClient) {
    let calledAt;
    $('.action__replace-recipe').on('click', async function () {
        if (calledAt && new Date() - calledAt < CALL_DELAY) {
            return;
        }
        calledAt = new Date();
        try {
            const recipeId = $(this).attr('data-recipeId');
            let similarRecipes = {};
            for (const deviation of [0.1, 0.2, 0.3, 0.4, 0.5]) {
                const result = await apiClient.getSimilarRecipes(recipeId, deviation, CookiesJS.get('auth_token'));
                if (!result.length) {
                    continue;
                }
                for (const recipe of result) {
                    if (!similarRecipes[recipe._id]) {
                        similarRecipes[recipe._id] = recipe;
                    }
                    if (Object.keys(similarRecipes).length >= 4) {
                        break;
                    }
                }
            }
            alert(`Похожие рецепты:\n${similarRecipes.map(item => ' - ' + item.title + ' ' + item._id).join('\n')}`);
        } catch (e) {
            alert('Что-то пошло не так');
            console.error(e);
            if (e.response) {
                console.log(e.response);
            }
        }
    });
}

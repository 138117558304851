class Config {
    constructor(data) {
        // public
        this.apiHostPublic = data.API_HOST_PUBLIC;
        this.staticBucket = data.STATIC_BUCKET_NAME;
        this.awsRegion = data.AWS_REGION;
        this.paymentIntegration = data.PAYMENTS_INTEGRATION;

        // internal
        this.fePort = data.FE_PORT || 8086;
        this.apiHost = data.API_HOST;

        // computed
        this.staticDownloadPath = `https://${this.staticBucket}.s3.${this.awsRegion}.amazonaws.com`;
    }

    expose() {
        return {
            API_HOST_PUBLIC: this.apiHostPublic,
            STATIC_BUCKET_NAME: this.staticBucket,
            AWS_REGION: this.awsRegion,
            PAYMENTS_INTEGRATION: this.paymentIntegration,
        };
    }
}

module.exports = {
    Config
};

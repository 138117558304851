import $ from 'jquery'
window.jQuery = $;

import { Client } from './client';
import { readConfigFromAPI } from './env-config-reader';
import ApiClient from '../lib/clients/apiClient';

async function main() {
    const config = await readConfigFromAPI();
    const apiClient = new ApiClient(config.apiHostPublic, config.staticDownloadPath, config.paymentIntegration);
    console.log('Config:', JSON.stringify(config, null, 4));
    const client = new Client($, apiClient);
    client.initialize();
}

$(document).ready(() => {
    main();
});
